(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard.discussions
   * @description
   *
   */
  angular
    .module('dashboard.discussions', [
      'ui.router',
      'discussions.view',
      'discussions.index'
    ]);
}());
